import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { SupportedLanguage } from './i18n.helper';

export type Translations = Record<string, string>;

export const setupI18next = (language: SupportedLanguage, translations: Translations = {}) => {
  const i18nInstance = i18n.createInstance();
  i18nInstance.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: language,
    returnEmptyString: false,
    resources: {
      [language]: {
        translation: translations,
      },
    },
    keySeparator: false,
    interpolation: { escapeValue: false },
  });

  return i18nInstance;
};
