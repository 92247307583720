/* eslint-disable no-param-reassign */

import { toQueryString } from '../../../play-core/utils/request-url-helper';
import { embedRoute } from '../pages/Embed/Embed.route';
import { popupVideoPlayerRoute } from '../pages/Embed/PopupVideoPlayer.route';
import { notFoundRoute } from '../pages/Error/PageNotFound.route';
import { favoriteGuideShowsPath, favoriteGuideShowsRoute } from '../pages/FavoriteGuide/FavoriteGuideShows.route';
import { favoriteGuideTopicsPath, favoriteGuideTopicsRoute } from '../pages/FavoriteGuide/FavoriteGuideTopics.route';
import { geoblockRoute } from '../pages/Help/Geoblock.route';
import { helpPageRoute } from '../pages/Help/HelpPage.route';
import { homeRoute } from '../pages/Home/HomePage.route';
import { legacyBrowserRoute } from '../pages/LegacyBrowser/LegacyBrowserPage.route';
import { liveEventsPageRoute, liveEventsPath } from '../pages/LiveEvent/LiveEventsPage.route';
import { microPagePath, microPageRoute } from '../pages/Micropage/Micropage.route';
import { myVideosPath, myVideosRoute } from '../pages/MyVideos/MyVideosPage.route';
import { searchRoute } from '../pages/Search/SearchPage.route';
import { sectionDetailPageRoute, sectionDetailPath } from '../pages/SectionDetail/SectionDetailPage.route';
import { settingsRoute } from '../pages/Settings/SettingsPage.route';
import { showPath, showRoute } from '../pages/Show/ShowPage.route';
import { showBroadcastPath, showBroadcastRoute } from '../pages/ShowBroadcast/ShowBroadcastPage.route';
import { showsOverviewRoute } from '../pages/ShowOverview/ShowOverviewPage.route';
import { topicPath, topicRoute } from '../pages/Topic/TopicPage.route';
import { tvGuideByChannelPath, tvGuideChannelRoute } from '../pages/TvGuideChannelPage/TvGuideChannelPage.route';
import { slugToday, tvGuidePath, tvGuideRoute } from '../pages/TvGuidePage/TvGuidePage.route';
import { defaultLiveTVRoute, livePagePath } from '../pages/TvLive/TvLivePage.route';
import { videoDetailPath, videoDetailRoute } from '../pages/VideoDetail/VideoDetailPage.route';
const getRoutes = (i18n, config) => {
  let routes = [homeRoute(), searchRoute(i18n), videoDetailRoute(), topicRoute(i18n), showRoute(i18n), showsOverviewRoute(i18n), defaultLiveTVRoute(i18n), sectionDetailPageRoute(), geoblockRoute(i18n), helpPageRoute(i18n), legacyBrowserRoute(), embedRoute(), popupVideoPlayerRoute(), favoriteGuideTopicsRoute(), favoriteGuideShowsRoute(), microPageRoute()];
  if (config.personalizationSupported) {
    routes = [settingsRoute(i18n), ...routes];
  }
  if (config.myVideosSupported) {
    routes = [myVideosRoute(i18n), ...routes];
  }
  if (config.showBroadcastSupported) {
    routes = [showBroadcastRoute(i18n), ...routes];
  }
  if (config.tvGuide) {
    routes = [tvGuideRoute(i18n), tvGuideChannelRoute(i18n), ...routes];
  }
  if (config.liveEvents) {
    routes = [liveEventsPageRoute(i18n), ...routes];
  }
  return [...routes, notFoundRoute()];
};
const getPaths = i18n => ({
  homePath: homeRoute().path,
  searchPath: searchRoute(i18n).path,
  settingsPath: settingsRoute(i18n).path,
  videoDetailPath,
  topicPath: topicPath(i18n),
  videoShowPath: show => showPath(i18n, show),
  showBroadcastPath: show => showBroadcastPath(i18n, show),
  livePath: livePagePath(i18n),
  liveEventsPath: liveEventsPath(i18n),
  tvGuidePath: (isoDate = slugToday(i18n.t)) => tvGuidePath(i18n, isoDate),
  tvGuideByChannelPath: (date, channel) => tvGuideByChannelPath(i18n, date, channel),
  showOverviewPath: showsOverviewRoute(i18n).path,
  playVideoDetailRedirectPath: (businessUnit, baseUrl, params) => `${baseUrl}/tv/redirect/detail/video/${businessUnit}${toQueryString(params)}`,
  playLiveRedirectPath: (businessUnit, baseUrl, {
    id,
    title
  }) => `${baseUrl}/tv/redirect/live/${businessUnit}${toQueryString({
    id,
    title
  })}`,
  helpPath: helpPageRoute(i18n).path,
  geoblockPath: geoblockRoute(i18n).path,
  myVideoPath: success => myVideosPath(i18n, success),
  sectionDetailPath: section => sectionDetailPath(section),
  favoriteGuideTopicsPath: callbackUrl => favoriteGuideTopicsPath(callbackUrl),
  favoriteGuideShowsPath: callbackUrl => favoriteGuideShowsPath(callbackUrl),
  microPagePath: page => microPagePath({
    title: page.title,
    pageId: page.id
  })
});
export const getAppLocation = (i18n, config) => {
  const paths = getPaths(i18n);
  const {
    baseUrl
  } = config;
  // TODO: Temporarily prefixing all paths with baseUrl to make client-side navigation work.
  // We have to find a better solution for this.
  return {
    routes: getRoutes(i18n, config),
    paths: {
      homePath: baseUrl + paths.homePath,
      searchPath: baseUrl + paths.searchPath,
      settingsPath: baseUrl + paths.settingsPath,
      videoDetailPath: (video, query) => baseUrl + paths.videoDetailPath(video, query),
      topicPath: topicName => baseUrl + paths.topicPath(topicName),
      videoShowPath: show => baseUrl + paths.videoShowPath(show),
      showBroadcastPath: show => baseUrl + paths.showBroadcastPath(show),
      livePath: livestream => baseUrl + paths.livePath(livestream),
      liveEventsPath: baseUrl + paths.liveEventsPath,
      tvGuidePath: (isoDate = slugToday(i18n.t)) => baseUrl + paths.tvGuidePath(isoDate),
      tvGuideByChannelPath: (date, channel) => baseUrl + paths.tvGuideByChannelPath(date, channel),
      showOverviewPath: baseUrl + paths.showOverviewPath,
      playVideoDetailRedirectPath: (businessUnit, base, params) => paths.playVideoDetailRedirectPath(businessUnit, base, params),
      playLiveRedirectPath: (businessUnit, base, {
        id,
        title
      }) => paths.playLiveRedirectPath(businessUnit, base, {
        id,
        title
      }),
      helpPath: baseUrl + paths.helpPath,
      geoblockPath: baseUrl + paths.geoblockPath,
      myVideoPath: success => baseUrl + paths.myVideoPath(success),
      sectionDetailPath: section => baseUrl + paths.sectionDetailPath(section),
      favoriteGuideTopicsPath: callbackUrl => baseUrl + paths.favoriteGuideTopicsPath(callbackUrl),
      favoriteGuideShowsPath: callbackUrl => baseUrl + paths.favoriteGuideShowsPath(callbackUrl),
      microPagePath: page => baseUrl + paths.microPagePath(page)
    }
  };
};