const pageSection = 'tv';
const path = `/${pageSection}/popupvideoplayer`;
export const popupVideoPlayerRoute = () => ({
  pageId: 'popupVideoPlayer',
  pageType: 'landingpage',
  pageSection,
  path,
  remixRoute: 'PopupVideoPlayer.remix-route.tsx',
  layout: {
    header: 'none',
    footer: 'none'
  },
  suppressBanner: true
});