const path = '/embed';
export const embedRoute = () => ({
  pageId: 'embed',
  pageType: 'landingpage',
  pageSection: 'tv',
  path,
  remixRoute: 'EmbedPage.remix-route.tsx',
  layout: {
    header: 'none',
    footer: 'none'
  },
  suppressBanner: true
});
export const getEmbedUrl = (baseUrl, videoDetail) => videoDetail.embedAllowed ? `${baseUrl}${path}?urn=${videoDetail.urn}` : undefined;