import { toSeoString } from '../../../../play-core/utils/to-seo-string';
const pageSection = 'tv';
export const showRoute = i18n => ({
  pageId: 'show',
  pageType: 'overview',
  pageSection,
  path: `/${pageSection}/${i18n.t('routes.show')}/:showTitle`,
  remixRoute: 'ShowPage.remix-route.tsx',
  showScrollTop: true
});
export const showPath = (i18n, show) => `/${pageSection}/${i18n.t('routes.show')}/${toSeoString(show.title)}?id=${show.id}`;