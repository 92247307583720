import { useEffect } from 'react';
// hook replaces the useLockBodyScroll from react-use library
// reason: stops fixed position modals from scrolling on IOS devices
// Issue: https://github.com/streamich/react-use/issues/1477
export const useLockBodyScroll = () => {
  useEffect(() => {
    const {
      body
    } = document;
    const scrollPosition = window.pageYOffset;
    body.style.overflow = 'hidden';
    body.style.position = 'fixed';
    body.style.top = `-${scrollPosition}px`;
    body.style.width = '100%';
    return () => {
      body.style.removeProperty('overflow');
      body.style.removeProperty('position');
      body.style.removeProperty('top');
      body.style.removeProperty('width');
      window.scrollTo(0, scrollPosition);
    };
  }, []);
};