const pageSection = 'tv';
export const myVideosRoute = i18n => ({
  pageId: 'myVideos',
  pageType: 'overview',
  pageSection,
  path: `/${pageSection}/${i18n.t('routes.myVideos')}`,
  remixRoute: 'MyVideosPage.remix-route.tsx'
});
export const myVideosPath = (i18n, success) => {
  if (success) {
    return `/${pageSection}/${i18n.t('routes.myVideos')}?success=${success}`;
  }
  return `/${pageSection}/${i18n.t('routes.myVideos')}`;
};