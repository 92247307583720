import { toQueryString } from '../../../../play-core/utils/request-url-helper';
import { toSeoString } from '../../../../play-core/utils/to-seo-string';
const pageSection = 'tv';
export const tvGuideChannelRoute = i18n => ({
  pageId: 'tvGuideByChannel',
  pageType: 'overview',
  pageSection,
  path: `/${pageSection}/${i18n.t('routes.tvGuideByChannel')}/:channelTitle?/:isoDate?`,
  remixRoute: 'TvGuideChannelPage.remix-route.tsx',
  showScrollTop: true
});
export const tvGuideChannelTitleIsoDateRoute = i18n => ({
  ...tvGuideChannelRoute(i18n),
  path: `/${pageSection}/${i18n.t('routes.tvGuideByChannel')}/:channelTitle/:isoDate`
});
export const tvGuideByChannelPath = (i18n, date, channel) => `/${pageSection}/${i18n.t('routes.tvGuideByChannel')}/${toSeoString(channel.title)}/${date}${toQueryString({
  channelUrn: channel.urn
})}`;