import { Suspense } from 'react';

import { loadPlayTranslations } from '@app/utils/i18n/load-play-translations';
import { setupI18next } from '@app/utils/i18n/setup-i18-next';
import { ClientMetaFunction } from '@app/utils/remix/client-meta';
import { handleCmpScript } from '@app/utils/remix/external-scripts/cmp-handler';
import { getLegacyLinksRedirect } from '@app/utils/server/controller/legacy-links-redirect.helper';
import { cacheControl } from '@app/utils/server/resources.server';
import { defaultInitialData, loadInitialRemixData } from '@app/utils/server/video-ssr/load-initial-remix-data.helper';
import { CoreConfigProvider } from '@play-now/core/config/CoreConfigContext';
import { GlobalStyles } from '@play-now/core/styles/GlobalStyles';
import { defaultTheme } from '@play-now/core/styles/Themes';
import { fontsBasePath } from '@play-now/core/utils/base-path';
import { BottomDialog } from '@play-now/video/components/BottomDialog/BottomDialog';
import { DebugWindow } from '@play-now/video/components/Debug/DebugWindow';
import { DialogContainer } from '@play-now/video/components/Dialog/DialogContainer';
import { getGlobalRemixMeta } from '@play-now/video/components/Meta/PlayMetaData';
import { PlayConfigProvider } from '@play-now/video/config/PlayConfigContext';
import { VideoAppProvider } from '@play-now/video/config/VideoAppContext';
import { SearchProvider } from '@play-now/video/pages/Search/SearchContext';
import { PrivacyProvider } from '@play-now/video/privacy/PrivacyProvider/PrivacyContext';
import { DataProvider } from '@play-now/video/providers/DataProvider/DataContext';
import { GlobalProviders } from '@play-now/video/providers/GlobalProviders';
import { VideoHistoryProvider } from '@play-now/video/providers/VideoHistoryProvider/VideoHistoryContext';
import { getAppLocation } from '@play-now/video/routes/route-factory';
import { VideoInitialData } from '@play-now/video/routes/Routes';
import { HeadersFunction, json, LinksFunction, LoaderFunctionArgs, redirect } from '@remix-run/node';
import { Outlet, useLoaderData } from '@remix-run/react';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';

import { setupPlayUtils } from './remix-route-helper';

export const headers: HeadersFunction = ({ loaderHeaders }) => ({
  'Cache-Control': loaderHeaders.get('Cache-Control') ?? cacheControl.regular,
});

export const links: LinksFunction = () => [
  { rel: 'preload', href: `${fontsBasePath}/SRGSSRTypeVF_Text_W_Wght.woff2`, as: 'font', type: 'font/woff2', crossOrigin: 'anonymous' },
];

export const loader = async ({ request, context, params }: LoaderFunctionArgs) => {
  const { config, i18n } = setupPlayUtils({ request, context, params });
  const url = new URL(request.url);

  // redirect layout without any nested routes to home
  if (url.pathname.endsWith(config.baseUrl) || url.pathname.endsWith(`${config.baseUrl}/`)) {
    return redirect(`${config.baseUrl}/tv`, { status: 301 });
  }

  const translations = loadPlayTranslations(config.language);
  const defaultData = {
    config,
    initialData: defaultInitialData(),
    translations,
    meta: getGlobalRemixMeta({ config, i18n, url: request.url }),
    serverError: null,
  };

  const legacyRedirect = getLegacyLinksRedirect(request, params, config, translations);
  if (legacyRedirect) return legacyRedirect;

  try {
    const initialData = await loadInitialRemixData(context, params, request);
    return json({ ...defaultData, initialData }, { status: 200 });
  } catch {
    // do not throw an error here, as we allways want to render the layout
    return json(
      {
        ...defaultData,
        serverError: 'InternalServerError',
      } satisfies PlayNowOutlet,
      { status: 500, statusText: 'InternalServerError' }
    );
  }
};

export const meta: ClientMetaFunction<typeof loader> = ({ data }) => data.meta;
export const handle = handleCmpScript;
export const shouldRevalidate = () => false;

type ServerError = 'InternalServerError' | 'RouteLoaderError' | null;
export type PlayNowOutlet = {
  serverError: ServerError;
};

const Index = () => {
  const { config, initialData, translations, serverError } = useLoaderData<typeof loader>();
  const i18n = setupI18next(config.language, translations);
  const appLocation = getAppLocation(i18n, config);
  const errorState = { serverError };

  return (
    <CoreConfigProvider config={config}>
      <PlayConfigProvider config={config}>
        <VideoAppProvider
          config={config}
          initialData={initialData as VideoInitialData}
          appLocation={appLocation}
          i18n={i18n}
          errorState={errorState}
        >
          <PrivacyProvider>
            <ThemeProvider theme={defaultTheme}>
              <GlobalProviders>
                <DataProvider initialTopics={initialData.topics}>
                  <VideoHistoryProvider>
                    <SearchProvider>
                      <Normalize />
                      <GlobalStyles />
                      {/* 
                        This global Suspense boundary ensures that styles are preserved if there is a hydration error.
                        While the error is still logged to the console, this prevents the user from seeing an unstyled page.
                      */}
                      <Suspense>
                        <Outlet />
                      </Suspense>
                      <DebugWindow />
                      <DialogContainer />
                      <BottomDialog />
                    </SearchProvider>
                  </VideoHistoryProvider>
                </DataProvider>
              </GlobalProviders>
            </ThemeProvider>
          </PrivacyProvider>
        </VideoAppProvider>
      </PlayConfigProvider>
    </CoreConfigProvider>
  );
};

export default Index;
