import { QueryClient } from '@tanstack/react-query';
import { isBrowser } from '../utils/ssr-helper';
export const queryConfig = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: () => 2000,
      staleTime: isBrowser ? 1 * 60 * 1000 : 0,
      gcTime: isBrowser ? 5 * 60 * 1000 : 0,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  }
});