import { toQueryString } from '../../../../play-core/utils/request-url-helper';
import { toSeoString } from '../../../../play-core/utils/to-seo-string';
const pageSection = 'tv';
const path = `/${pageSection}/detail`;
export const sectionDetailPageRoute = () => ({
  pageId: 'event',
  pageType: 'detail',
  pageSection,
  path: `${path}/:title`,
  remixRoute: 'SectionDetailPage.remix-route.tsx'
});
export const sectionDetailPath = section => `${path}/${toSeoString(section.representation.title)}${toQueryString({
  id: section.id
})}`;