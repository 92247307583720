const pageSection = 'tv';
const path = `/${pageSection}/favorite-guide-topics`;
export const favoriteGuideTopicsRoute = () => ({
  pageId: 'favoriteGuideTopics',
  pageType: 'guide',
  pageSection,
  path,
  remixRoute: 'FavoriteGuideTopics.remix-route.tsx',
  showScrollTop: true,
  layout: {
    footer: 'none'
  }
});
export const favoriteGuideTopicsPath = callbackUrl => `${path}?callback=${callbackUrl}`;