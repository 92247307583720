/* eslint-disable no-param-reassign */

import { toQueryString } from '../../../../play-core/utils/request-url-helper';
import { toSeoString } from '../../../../play-core/utils/to-seo-string';
const pageSection = 'tv';
export const videoDetailRoute = () => ({
  pageId: 'VIDEO',
  pageType: 'detail',
  pageSection: 'tv',
  layout: {
    header: 'none'
  },
  path: `/${pageSection}/:showName/video/:episodeTitle`,
  remixRoute: 'VideoDetailPage.remix-route.tsx'
});
export const videoDetailPath = (video, query = {}) => {
  const videoTitle = video.title || '-';
  const showTitle = video.show?.title || '-';
  delete query.id;
  delete query.urn;
  const queries = toQueryString(query).replace(/^\?/, '&');
  // toQueryString will escape urn-characters --> new videodetail url
  const queryString = `?urn=${video.urn}${queries}`;
  return `/${pageSection}/${toSeoString(showTitle)}/video/${toSeoString(videoTitle)}${queryString}`;
};