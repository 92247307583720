import { loader } from '@app/play-now/routes/PlayApp.remix-route';
import { SerializeFrom } from '@remix-run/node';

import { ExternalScriptsHandle } from './external-scripts';

type LoaderData = SerializeFrom<typeof loader>;

const onCmpError = () => {
  // this function is later defined in the head of the document and has no access to external variables
  window.__PubSub_PLAY__.publish('cmpLoadError', true);
};

// Supress the privacy banner on these paths
const supressBannerPaths = ['/embed', '/tv/popupvideoplayer'];

export const handleCmpScript: ExternalScriptsHandle<LoaderData> = {
  scripts({
    data: {
      config: {
        privacyBanner: { isEnabled, rulesetId },
      },
    },
    matches,
  }) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const supressBanner = supressBannerPaths.some((path) => matches.some((match) => (match as any).pathname.includes(path)));
    if (isEnabled && !supressBanner) {
      return [
        {
          id: 'usercentrics-cmp',
          src: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
          rulesetId,
          onError: onCmpError,
          async: true,
        },
      ];
    }
    return [];
  },
};
