import { toQueryString } from '../../../../play-core/utils/request-url-helper';
import { toSeoString } from '../../../../play-core/utils/to-seo-string';
const pageSection = 'tv';
const basePath = `/${pageSection}/micropages`;
export const microPageRoute = () => ({
  pageId: 'micropage',
  pageType: 'landingpage',
  pageSection,
  path: `${basePath}/:pageName`,
  remixRoute: 'Micropage.remix-route.tsx'
});
export const microPagePath = ({
  title,
  pageId,
  ...query
}) => `${basePath}/${toSeoString(title)}${toQueryString({
  pageId,
  ...query
})}`;