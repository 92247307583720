import { styled, keyframes } from 'styled-components';
import { headerHeight } from './BottomDialogHeader';
import { Colors } from '../../../../play-core/styles/Colors';
import { smallScrollbar } from '../../../../play-core/styles/style-helper';
import { Viewports } from '../../../../play-core/styles/Viewports';
import { zIndex } from '../../../../play-core/styles/z-index';
const height = 480;
const slideUp = keyframes(["0%{transform:translateY(", "px);}100%{transform:translateY(0);}"], height);
export const Overlay = styled.div.withConfig({
  displayName: "BottomDialogstyles__Overlay",
  componentId: "sc-1jekxdk-0"
})(["position:fixed;z-index:", ";background-color:rgba(0,0,0,0.5);bottom:0;left:0;right:0;top:0;transition:opacity 200ms ease-in-out;"], zIndex.dialog);
export const StyledDialogContent = styled.div.withConfig({
  displayName: "BottomDialogstyles__StyledDialogContent",
  componentId: "sc-1jekxdk-1"
})(["position:fixed;bottom:0;left:0;right:0;width:100%;height:min(", "px,100dvh);padding:0px 0 0 0;margin:0;animation:", " 200ms ease-in-out forwards;background-color:", ";", "{top:0;padding:0;height:100dvh;background-color:", ";}"], height, slideUp, Colors.GREY_2a2a2a, Viewports.for('small'), Colors.GREY_1a1a1a);
export const StyledContainer = styled.div.withConfig({
  displayName: "BottomDialogstyles__StyledContainer",
  componentId: "sc-1jekxdk-2"
})(["height:100%;padding-top:48px;display:flex;justify-content:center;color:", ";overflow-y:auto;-webkit-overflow-scrolling:touch;", ";", "{padding-top:", "px;}"], Colors.GREY_d2d2d2, smallScrollbar(), Viewports.for('small'), headerHeight);