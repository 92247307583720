export const legacyBrowserRoute = () => ({
  pageId: 'legacyBrowser',
  pageType: 'navigationpage',
  pageSection: 'tv',
  path: '/legacy-browser',
  remixRoute: 'LegacyBrowserPage.remix-route.tsx',
  layout: {
    header: 'none',
    footer: 'none'
  }
});