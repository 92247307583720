const pageSection = 'tv';
const path = `/${pageSection}/favorite-guide-shows`;
export const favoriteGuideShowsRoute = () => ({
  pageId: 'favoriteGuideShows',
  pageType: 'guide',
  pageSection,
  path,
  remixRoute: 'FavoriteGuideShows.remix-route.tsx',
  showScrollTop: true,
  layout: {
    footer: 'none'
  }
});
export const favoriteGuideShowsPath = callbackUrl => `${path}?callback=${callbackUrl}`;