const pageSection = 'tv';
export const homePath = () => `/${pageSection}`;
export const homeRoute = () => ({
  pageId: 'home',
  pageType: 'landingpage',
  pageSection,
  path: homePath(),
  remixRoute: 'HomePage.remix-route.tsx',
  layout: {
    header: 'transparent'
  }
});